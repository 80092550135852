<template>
  <b-card body-class="d-flex flex-column" :border-variant="borderVariant">
    <!-- Address Contents -->
    <div class="mb-3">
      <!-- Name -->
      <!-- <b></b> -->
      <!-- Appartment -->
      <p v-if="address.otherDetails">{{ address.otherDetails }}</p>
      <!-- Area -->
      <p v-if="address.area">{{ address.area }}</p>
      <!-- City, (State) PinCode -->
      <p>{{ address.city }}, {{ address.state }} {{ address.pincode }}</p>
    </div>

    <!-- Links -->
    <div class="mt-auto flex-auto">
      <!-- Slot to provide for set Delivery Address -->
      <slot name="footer" />

      <div>
        <small>
          <a @click.prevent="edit" href="#" class="mr-2">Edit</a>
          |
          <a @click.prevent="remove" href="#" class="ml-2">Remove</a>
        </small>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import { deleteAddress } from "../../../apis/user";
export default {
  props: ["userAddress", "borderVariant"],
  created() {
    this.address = { ...this.userAddress };
  },
  data() {
    return {
      address: {
        id: "",
        pincode: "",
        city: "",
        area: "",
        otherDetails: "",
        landmark: "",
        state: "",
        // latitude: null,
        // longitude: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
  },
  methods: {
    edit() {
      // redirect to form for Edit
      this.$router.push({
        name: "UserAddressForm",
        query: { addressId: this.address.id },
      });
    },
    remove() {
      deleteAddress(this.address.id, this.token).then(() => {
        this.$emit("removeSuccess");
      });
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}
</style>
