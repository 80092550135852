<template>
  <b-row>
    <!-- <b-col offset-sm="1" sm="10" offset-lg="2" lg="8"> -->
    <b-col>
      <b-card>
        <!-- Title with Add Button -->
        <div class="d-flex justify-content-between text-primary">
          <!-- Title -->
          <b-card-title>Your Addresses</b-card-title>

          <!-- Add Button -->
          <b-card-title>
            <b-icon-plus-circle @click="addAddress" />
          </b-card-title>
        </div>

        <!-- List of Address -->
        <template v-if="count > 0">
          <b-row>
            <b-card-group
              class="col-sm-4 col-xl-3"
              v-for="userAddress in sortedUserAddresses"
              :key="userAddress.id"
            >
              <AddressCard
                :userAddress="userAddress"
                class="mb-3"
                @removeSuccess="() => removeSuccess(userAddress)"
              />
            </b-card-group>
          </b-row>
        </template>

        <!-- No Address -->
        <template v-else>
          <b-alert show variant="info" class="text-center mt-5">
            <h4 class="alert-heading">No Addresses Registered!</h4>
          </b-alert>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import { getAddresses } from "../../../../apis/user";
import AddressCard from "../../../../components/account/address/AddressCard.vue";
export default {
  components: { AddressCard },
  created() {
    getAddresses(this.token).then((data) => {
      this.userAddresses = [...data];
    });
  },
  data() {
    return {
      userAddresses: [],
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
    }),
    count() {
      return this.userAddresses.length;
    },
    sortedUserAddresses() {
      const addresses = [...this.userAddresses];
      addresses.sort((address1, address2) => {
        const time1 = address1.lastUsedTime ? address1.lastUsedTime : 0;
        const time2 = address2.lastUsedTime ? address2.lastUsedTime : 0;
        return time2 - time1;
      });
      return addresses;
    },
  },
  methods: {
    addAddress() {
      // redirect to form for new
      this.$router.push({ name: "UserAddressForm" });
    },
    removeSuccess(userAddress) {
      this.removeItemFromList(this.userAddresses, userAddress, "id");
    },
  },
};
</script>
